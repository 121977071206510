<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g transform="translate(0.176)">
        <g transform="translate(0)">
          <g transform="translate(0 0)">
            <g transform="translate(0)">
              <circle  cx="14" cy="14" r="14" class="share-link" transform="translate(-0.176)" :class="isClicked ? 'fill' : 'no-fill'" />
              <path
                class="b"
                d="M20.052,7.637a6.6,6.6,0,0,1-1.539,1.586c.009.133.009.266.009.4A8.733,8.733,0,0,1,5.082,16.974a6.343,6.343,0,0,0,.741.038A6.144,6.144,0,0,0,9.632,15.7a3.073,3.073,0,0,1-2.869-2.128,3.866,3.866,0,0,0,.579.047,3.244,3.244,0,0,0,.807-.1,3.068,3.068,0,0,1-2.46-3.011v-.038a3.09,3.09,0,0,0,1.387.389,3.073,3.073,0,0,1-.95-4.1,8.721,8.721,0,0,0,6.326,3.211,3.463,3.463,0,0,1-.076-.7,3.071,3.071,0,0,1,5.31-2.1,6.04,6.04,0,0,0,1.947-.741,3.06,3.06,0,0,1-1.349,1.691A6.151,6.151,0,0,0,20.052,7.637Z"
                transform="translate(1.345 1.639)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
  export default {
    props: ["isClicked"],
    data() {
      return {}
    }
  }
</script>

<style>
svg{
  display:block;
  width:100%;
}
.b {
  fill: #1b1b1b;
  fill-rule: evenodd;
}
</style>